<template>
  <v-dialog v-model="show" persistent width="unset" @keyup.enter="ok">
    <v-card :color="colorTheme.info" dark>
      <div class="pt-2">
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
      </div>
      <v-btn
        block
        :style="textColor"
        :color="colorTheme.buttonWhite"
        @click="ok"
      >
        OK
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["showDialog", "message"],
  emits: ["update:showDialog", "confirm"],
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    textColor() {
      return {
        color: this.colorTheme.buttonText
      };
    },
  },
  methods: {
    ok() {
      this.$emit("confirm");
      this.$emit("update:showDialog", false);
    }
  }
};
</script>
