<template>
  <v-container fluid>
    <redirection-confirmation-dialog
      :showDialog.sync="runCalcStartSuccess"
      :message="runStartedMessage"
      @confirm="back"
    ></redirection-confirmation-dialog>
    <review-fees-and-schedules
      screen="feeResults"
      :items="calculationResults"
      :itemsMultiPeriod="calculationResultsMultiPeriod"
      :allSelected="feeResultsAllSelected"
      :selectedPeriod="selectedDate"
      :allItemsLoaded.sync="allItemsLoaded"
      :accounts="selectedAccounts"
      :showBillingRecordsErrorDialog.sync="showBillingRecordsErrorDialog"
      :showCalculationErrorDialog.sync="showCalculationErrorDialog"
      :calculationWarningDialog.sync="calculationWarningDialog"
      :calculationWarningMessage.sync="calculationWarningMessage"
      :totalCalculationResults="totalCalculationResults"
      :totalCalculationResultsMultiPeriod="totalCalculationResultsMultiPeriod"
      :refresh="refresh"
      :refreshOrCut="refreshOrCut"
      :refreshMultiPeriod="refreshMultiPeriod"
      :refreshOrCutMultiPeriod="refreshOrCutMultiPeriod"
      :refreshAndModify="refreshAndModify"
      :reRun="reRun"
      :selectAllAccounts="selectAllAccounts"
      :selectAllAccountsMultiPeriod="selectAllAccountsMultiPeriod"
      :resetMultiPeriodResults="resetMultiPeriodResults"
      :resetCalculationResults="resetCalculationResults"
      @back="back"
      @set-accounts-for-re-run="setAccountsForReRun"
      @set-accounts-for-re-run-multi-period="setAccountsForReRunMultiPeriod"
    ></review-fees-and-schedules>
  </v-container>
</template>

<script>
import { getPreviousRunIntFromString } from "@/utils/period-util.js";
import { mapActions, mapMutations, mapState } from "vuex";
import ReviewFeesAndSchedules from "@/components/billing-records/ReviewFeesAndSchedules.vue";
import RedirectionConfirmationDialog from "@/components/dialogs/RedirectionConfirmationDialog.vue";

export default {
  props: ["previousScreen"],
  components: {
    ReviewFeesAndSchedules,
    RedirectionConfirmationDialog,
  },
  data: () => ({
    calculationWarningDialog: false,
    calculationWarningMessage: "",
    runStartedMessage: "Running of calculation started. You will be redirected to Fee Review page. Check calculation status in top right corner.",
    runCalcStartSuccess: false,
    showBillingRecordsErrorDialog: false,
    showCalculationErrorDialog: false,
    allItemsLoaded: [],
  }),
  computed: {
    ...mapState("feeResultsAccounts", [
      "selectedAccounts",
      "feeResultsAllSelected",
      "selectedDate",
    ]),
    ...mapState("feeResultsRecords", [
      "calculationResults",
      "calculationResultsMultiPeriod",
      "totalCalculationResults",
      "totalCalculationResultsMultiPeriod",
      "accountsForReRun",
      "accountsForCalculation",
      "accountsForReRunMultiPeriod"
    ]),
    ...mapState("billingRecordsCalculations", ["billingRecordsPagination"]),
  },
  watch: {
    calculationResultsMultiPeriod: {
      deep: true,
      handler(val) {
        val.forEach(acc => {
          let index = this.allItemsLoaded.findIndex(
            a =>
              a.accountId == acc.accountId &&
              a.billingRunType == acc.billingRunType
          );
          if (index > -1) {
            this.allItemsLoaded[index] = acc;
          } else {
            this.allItemsLoaded.push(acc);
          }
        });
      }
    },
  },
  methods: {
    ...mapMutations("feeResultsRecords", [
      "setCalculationResults",
      "setTotalCalculationResults",
      "setCalculationResultsMultiPeriod",
      "setTotalCalculationResultsMultiPeriod",
      "setAccountsForReRun",
      "setAccountsForReRunMultiPeriod",
      "resetPeriodRangeFilter",
    ]),
    ...mapActions("feeResultsRecords", [
      "getAccountRecords",
      "getBillingRecordsPaged",
      "getBillingRecordsForMultiplePeriods",
      "reRunCalculation",
      "getAllBillingRecordsPaged",
      "getAllMultiPeriod",
      "runCalculationMultiPeriod",
    ]),
    async reRun(payload) {
      let result;
      let all;
      let accounts;
      if (payload.all) {
        if (payload.multiPeriod) {
          let accountsInPeriod = [];
          this.calculationResultsMultiPeriod.forEach(acc => {
            acc.accountsInPeriod.forEach(accInPeriod => {
              accountsInPeriod.push({
                id: accInPeriod.accountId,
                billingRunType: accInPeriod.billingRunType,
                billingPeriod: accInPeriod.billingPeriod
              });
            });
          });
          accounts = accountsInPeriod;
        } else {
          accounts = this.accountsForCalculation;
        }
        all = this.feeResultsAllSelected;
      } else {
        all = false;
        if (payload.multiPeriod) {
          accounts = this.accountsForReRunMultiPeriod;
        } else {
          accounts = this.accountsForReRun;
        }
      }

      if (payload.multiPeriod) {
        result = await this.runCalculationMultiPeriod({
          page: 1,
          pageSize: this.billingRecordsPagination.itemsPerPage,
          billingPeriod: getPreviousRunIntFromString(this.selectedDate),
          all: all,
          accounts: accounts
        });
      } else {
        result = await this.reRunCalculation({
          page: 1,
          pageSize: payload.itemsPerPage,
          all: all,
          accounts: accounts
        });
      }

      if (result.warningMessage) {
        this.calculationWarningMessage = result.warningMessage;
        this.calculationWarningDialog = true;
      } else if (result.error) {
        this.showCalculationErrorDialog = true;
      } else if (result.timeout) {
        this.runCalcStartSuccess = true;
      } else if (!result.responseData) {
        this.showCalculationErrorDialog = true;
      }
    },
    async refreshAndModify(payload) {
      let result = await this.getAccountRecords(payload);
      return result;
    },
    async refreshOrCut(options) {
      let array = this.calculationResults;
      if (options.itemsPerPage > array.length) {
        await this.refresh(options);
      } else {
        this.setCalculationResults(
          this.calculationResults.slice(0, options.itemsPerPage)
        );
      }
    },
    async refresh(options) {
      let result = await this.getBillingRecordsPaged({
        page: options.page,
        pageSize: options.itemsPerPage,
        all: this.feeResultsAllSelected,
        accounts: this.accountsForCalculation
      });

      if (!result) {
        this.showBillingRecordsErrorDialog = true;
        return false;
      }
      return true;
    },
    async refreshOrCutMultiPeriod(options) {
      let array = this.calculationResultsMultiPeriod;
      if (options.itemsPerPage > array.length) {
        await this.refreshMultiPeriod(options);
      } else {
        this.setCalculationResultsMultiPeriod(
          this.calculationResultsMultiPeriod.slice(0, options.itemsPerPage)
        );
      }
    },
    async refreshMultiPeriod(options) {
      let result = await this.getBillingRecordsForMultiplePeriods({
        page: options.page,
        pageSize: options.itemsPerPage,
        billingPeriod: getPreviousRunIntFromString(this.selectedDate),
        all: this.feeResultsAllSelected,
        accounts: this.accountsForCalculation
      });

      if (!result) {
        this.showBillingRecordsErrorDialog = true;
        return false;
      }
      return true;
    },
    resetMultiPeriodResults() {
      this.setCalculationResultsMultiPeriod([]);
      this.setTotalCalculationResultsMultiPeriod(0);
    },
    resetCalculationResults() {
      this.setCalculationResults([]);
      this.setTotalCalculationResults(0);
    },
    resetAll() {
      this.resetPeriodRangeFilter();
      this.resetMultiPeriodResults();
      this.resetCalculationResults();
    },
    back() {
      this.resetAll();
      if (this.previousScreen) {
        this.$router.replace({ name: this.previousScreen });
      } else {
        this.$router.replace({ name: "FeeResultsAccounts" });
      }
    },
    async selectAllAccounts(options) {
      options.accounts = this.accountsForCalculation;
      let allAccounts = await this.getAllBillingRecordsPaged(options);
      return allAccounts;
    },
    async selectAllAccountsMultiPeriod(options) {
      options.all = this.feeResultsAllSelected;
      options.accounts = this.accountsForCalculation;
      options.billingPeriod = getPreviousRunIntFromString(this.selectedDate);
      let allAccounts = await this.getAllMultiPeriod(options);
      return allAccounts;
    },
  },
};
</script>
